import styled from '@emotion/styled'
import ImageBanner from '../image-banner/image-banner'
import { Title as TitleBase } from '../image-banner/image-banner.styled'

export const ImageBannerStyled = styled(ImageBanner)`
	a {
		:first-of-type {
			span {
				border-radius: var(--card-border-radius) !important;
			}
		}
	}
	&.big {
		grid-column: span 2;
		grid-row: span 2;
	}
`

export const Title = styled(TitleBase)`
	bottom: 10px;
	left: 10px;
	top: auto;
	transform: none;
	font-size: ${({ theme }) => theme.typography.pxToRem(16)};
	text-transform: none;
	text-align: left;
	${({ theme }) => theme.breakpoints.up('md')} {
		font-size: ${({ theme }) => theme.typography.pxToRem(16)};
	}
`
