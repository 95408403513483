import styled from '@emotion/styled'

const shouldForwardProp = (prop) => !['columns', 'spacing', 'width', 'height'].includes(prop)

export const MasonryContainer = styled('div', { shouldForwardProp })`
	overflow-x: scroll;
	::-webkit-scrollbar {
		display: none;
	}
	scrollbar-width: none;
	${({ theme }) => theme.breakpoints.up('md')} {
		overflow-x: initial;
		::-webkit-scrollbar {
			display: initial;
		}
		scrollbar-width: initial;
	}
`

export const Masonry = styled('div', { shouldForwardProp })`
	display: grid;
	grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
	grid-gap: ${({ theme, spacing }) => theme.spacing(spacing)};
	padding: ${({ theme }) => theme.spacing(0, 2)};
	width: ${({ columns }) => `calc(${columns} * 212px)`};
	${({ theme }) => theme.breakpoints.up('md')} {
		padding: ${({ theme }) => theme.spacing(0, 5.5)};
		width: 100%;
	}
`
