import MasonryGridItem from '@/components/ui/organisms/masonry-grid/masonry-grid-item'
import { cx } from '@emotion/css'
import Box from '@mui/material/Box'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/system'
import { Children } from 'react'
import BannerImage from '../banner-image/banner-image'
import { Masonry, MasonryContainer } from './slider-masonry-grid.styled'

const SliderRenderer = ({ aboveTheFold, minHeight, sx, cssProp, className, children }) => {
	const theme = useTheme()
	const mdUp = useMediaQuery(theme.breakpoints.up('md'))
	return (
		<Box
			className={cx('Magento-PageBuilder-Slider', className)}
			sx={{ ...sx, minHeight, width: '100%', height: aboveTheFold ? '100%' : undefined }}
			css={cssProp}
		>
			<MasonryContainer className={cx('MasonryGrid-container', className)}>
				<Masonry className="MasonryGrid-root" columns={6} spacing={mdUp ? 2 : 1}>
					{Children.map(children, ({ props: { data } = {} } = {}) => {
						const isBig = data?.className?.includes('big')

						return (
							<BannerImage className={cx('MasonryGrid-item', isBig && 'big')} {...data} Component={MasonryGridItem} />
						)
					})}
				</Masonry>
			</MasonryContainer>
		</Box>
	)
}

export default SliderRenderer
