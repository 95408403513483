import { cx } from '@emotion/css'
import ImageBanner from '../image-banner/image-banner'
import { ImageBannerStyled, Title } from './masonry-grid.styled'

const MasonryGridItem = ({ className, ...props }) => {
	return <ImageBannerStyled className={cx('MasonryGrid-item', className)} {...props} TitleComponent={Title} />
}

MasonryGridItem.defaultProps = {
	...ImageBanner.defaultProps,
}

MasonryGridItem.propTypes = {
	...ImageBanner.propTypes,
}

export default MasonryGridItem
